<template>
	<ion-content :fullscreen="true">
		<ion-progress-bar type="indeterminate" v-if="!hasItemsList"></ion-progress-bar>
		<div class="menu" v-if="menuItems.length>0">
			<ion-item v-for="item in menuItems" :key="item.id">
				<ion-button v-if="!item.link" fill="clear" color="primary" @click="$emit('selected', item)">
					<ion-icon :src="getIcon(item.icon)"></ion-icon>
					<ion-label>{{ item.text }}</ion-label>
				</ion-button>
				<router-link v-else :to="item.link"><!-- :to="`/search`"--> <!-- :to="`/search?direction=shipments/deliveries`"-->
					<ion-icon :src="getIcon(item.icon)"></ion-icon>
					{{ item.text }}
				</router-link>
			</ion-item>
		</div>
		<div class="warning" v-else>
			<ion-item lines="none">
				<ion-label>{{ msgNoModules }}</ion-label>
			</ion-item>
		</div>
	</ion-content>
</template>

<style scoped>
	ion-label {
		font-size: 16px;
		margin-left: 2vw;
	}
	a {
		text-decoration: none;
	}
	a ion-icon {
		font-size: 16px;
		margin-left: 2vw;
	}
</style>

<script>
import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonProgressBar } from '@ionic/vue';
import * as allIcons from "ionicons/icons";
import { defineComponent } from 'vue';

	export default defineComponent({
		props: {
			menuItems: { type: Array, default: () => [] },
			isReady: { type: Boolean, default: true },
			msgNoModules: { type: String, default: "NESSUN MODULO ABILITATO !" },
		},
		components: {
			IonContent,
			IonProgressBar,
			IonItem,
			IonButton,
			IonIcon,
			IonLabel,
		},
		data: () => ({
			icons: allIcons,
			hasItemsList: true,
		}),
		mounted () {
			this.hasItemsList = this.isReady;
		},
		watch: {
			isReady () {
				this.hasItemsList = this.isReady;
			},
  },
		methods: {
			getIcon(name) {
				return this.icons[name];
			},
		},
	});
</script>
