import tables from "@/store/thecore/tables";
import { createStore } from "vuex";

const store = createStore({
	modules: {
		tables,
	},
	states: {},
	mutations: {},
	actions: {},
});

export default store;
