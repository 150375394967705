import axios from "@/plugins/axiosApi";
import { publicAccess } from "@/plugins/variables.js";
import router from '@/router/index';

export default {
	namespaced: true,

	state: {
		token: null,
	},

	getters: {
		isAuthenticated: (state) => !!state.token,
		token: (state) => state.token,
	},

	mutations: {
		setToken: (state, payload) => {
			localStorage.setItem("user-token", JSON.stringify(payload));
			state.token = payload;
		},
		removeToken: (state) => {
			localStorage.removeItem("user-token");
			state.token = null;
		},
	},

	actions: {
		login: async ({
			commit
		}, {
			model,
			data
		}) => {
			commit("removeToken");
			try {
				const response = await axios
					.post(`/${model}`, data, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
					});
				if (response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				commit("removeToken");
				return await Promise.reject(error.response);
			}
		},
		setTokenFromLocalStorage: ({
			commit
		}) => {
			let token = localStorage.getItem("user-token") || null;
			commit("setToken", JSON.parse(token));
		},
		logout: ({
			commit
		}) => {
			commit("removeToken");
		},
		fetch: async ({
			commit
		}, {
			model,
			updateToken = true
		}) => {
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			const newUrl = model.toString().includes("?") ?
				`/${model}&cb=${Date.now()}` :
				`/${model}?cb=${Date.now()}`;
			try {
				const response = await axios
					.get(newUrl /*`/${model}}`*/, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${myToken}`,
						},
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				if (error && error.response && error.response.status === 404 && error.response.data && !error.response.data.error) {
					return error.response.data; //return null;
				} else {
					return await Promise.reject(error.response);
				}
			}
		},
		create: async ({
			commit
		}, {
			model,
			data,
			updateToken = true
		}) => {
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			try {
				const response = await axios
					.post(`/${model}`, data, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${myToken}`,
						},
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		createForm: async ({
			commit
		}, {
			model,
			data,
			updateToken = true
		}) => {
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			try {
				const response = await axios
					.post(`/${model}`, data, {
						headers: {
							Accept: "application/json",
							"Content-Type": "multipart/form-data",
							Authorization: `Bearer ${myToken}`,
						},
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		read: async ({
			commit
		}, {
			model,
			id,
			updateToken = true
		}) => {
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			try {
				const response = await axios
					.get(`/${model}/${id}`, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${myToken}`,
						},
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		update: async ({
			commit
		}, {
			model,
			id,
			data,
			updateToken = true
		}) => {
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			try {
				const response = await axios
					.put(`/${model}/${id}`, data, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${myToken}`,
						},
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		bulkUpdate: async ({
			commit
		}, {
			model,
			id,
			data,
			updateToken = true
		}) => {
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			try {
				const response = await axios
					.put(`/${model}/${id}?do=bulk_update_rows`, data, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${myToken}`,
						},
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		updateForm: async ({
			commit
		}, {
			model,
			id,
			data,
			updateToken = true
		}) => {
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			try {
				const response = await axios
					.put(`/${model}/${id}`, data, {
						headers: {
							Accept: "application/json",
							"Content-Type": "multipart/form-data",
							Authorization: `Bearer ${myToken}`,
						},
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		bulkUpdateForm: async ({
			commit
		}, {
			model,
			id,
			data,
			updateToken = true
		}) => {
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			try {
				const response = await axios
					.put(`/${model}/${id}?do=bulk_update_signatures`, data, {
						headers: {
							Accept: "application/json",
							"Content-Type": "multipart/form-data",
							Authorization: `Bearer ${myToken}`,
						},
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		delete: async ({
			commit,
			rootGetters
		}, {
			model,
			id,
			updateToken = true
		}) => {
			try {
				const response = await axios
					.delete(`/${model}/${id}`, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${rootGetters["tables/token"]}`,
						},
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		querySearch: async ({
			commit
		}, {
			model,
			data,
			updateToken = true
		}) => {
			let json = null;
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			data.params["cb"] = Date.now();
			try {
				const response = await axios
					.post(`/${model}/search`, json, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${myToken}`,
						},
						params: data.params,
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				if (error && error.response && error.response.status === 404) { // && error.response.data && !error.response.data.error
					return error.response.data;
				} else {
					return await Promise.reject(error.response);
				}
			}
		},
		jsonSearch: async ({
			commit
		}, {
			model,
			data,
			updateToken = true
		}) => {
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			data['cb'] = Date.now();
			try {
				const response = await axios
					.post(`/${model}/search`, data, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${myToken}`,
						},
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				if (error && error.response && error.response.status === 404) { // && error.response.data && !error.response.data.error
					return error.response.data;
				} else {
					return await Promise.reject(error.response);
				}
			}
		},
		count: async ({
			commit,
			rootGetters
		}, {
			model,
			data,
			updateToken = true
		}) => {
			data.params["count"] = "true";
			try {
				const response = await axios
					.get(`/${model}`, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${rootGetters["tables/token"]}`,
						},
						params: data.params,
					});
				if (updateToken === true && response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		getInfoSchema: async ({
			commit,
			rootGetters
		}, model) => {
			try {
				const response = await axios
					.get(`/${model}`, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${rootGetters["tables/token"]}`,
						},
					});
				if (response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		getInfoDsl: async ({
			commit,
			rootGetters
		}, model) => {
			try {
				const response = await axios
					.get(`/${model}`, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${rootGetters["tables/token"]}`,
						},
					});
				if (response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
		getInfoSettings: async ({
			commit
		}, model) => {
			var tk = localStorage.getItem("user-token")
			if (tk && tk !== "undefined") var myToken = JSON.parse(tk);
			else if(publicAccess) router.push("/");
			else router.push("/login");
			try {
				const response = await axios
					.get(`/${model}`, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization: `Bearer ${myToken}`,
						},
					});
				if (response.headers.token) commit("setToken", response.headers.token);
				return response.data;
			} catch (error) {
				return await Promise.reject(error.response);
			}
		},
	},
};