import store from '../store/index';

export async function getData(tableName) {
	let data = await store.dispatch("tables/fetch", { model: tableName });
	return data;
}
export async function writeData(tableName, record) {
	let data = await store.dispatch("tables/create", { model: tableName, data: record });
	return data;
}
export async function writeFormData(tableName, record) {
	let data = await store.dispatch("tables/createForm", { model: tableName, data: record });
	return data;
}
export async function readData(tableName, recordId) {
	let data = await store.dispatch("tables/read", { model: tableName, id: recordId });
	return data;
}
export async function updateData(tableName, recordId, record) {
	let data = await store.dispatch("tables/update", { model: tableName, id: recordId, data: record });
	return data;
}
export async function bulkUpdateData(tableName, recordId, record) {
	let data = await store.dispatch("tables/bulkUpdate", { model: tableName, id: recordId, data: record });
	return data;
}
export async function updateFormData(tableName, recordId, record) {
	let data = await store.dispatch("tables/updateForm", { model: tableName, id: recordId, data: record });
	return data;
}
export async function bulkUpdateFormData(tableName, recordId, record) {
	let data = await store.dispatch("tables/bulkUpdateForm", { model: tableName, id: recordId, data: record });
	return data;
}
export async function deleteData(tableName, recordId) {
	let data = await store.dispatch("tables/delete", { model: tableName, id: recordId });
	return data;
}
export async function searchQueryData(tableName, filters) {
	let data = await store.dispatch("tables/querySearch", { model: tableName, data: filters });
	return data;
}
export async function searchJsonData(tableName, filters) {
	let data = await store.dispatch("tables/jsonSearch", { model: tableName, data: filters });
	return data;
}
