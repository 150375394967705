<template>
	<ion-page>
		<spot-header :title="pageTitle" :canGoHome="true" :canGoBack="false" @pressedGoHome="$router.push('/home')" @pressedGoBack="$router.push('/home')"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readerError="onReaderError"></web-socket-reader>
				<spot-select-custom
					:ref="projectField.name"
					:label="projectField.text"
					:allignLabel="projectField.allignLabel"
					:required="projectField.required"
					:placeholder="getPlaceHolder(projectField)"
					:enabled="getEnabled(projectField, projectField.enabled)"
					:enabledExtraButtons="getEnabled(projectField, projectField.enabledExtraButtons)"
					:value="projectField.value"
					:options="tablesData.projects"
					:optionField="projectField.refField"
					:validationState="projectField.validationState"
					:canAddItem="projectField.canAddItem"
					:canRefreshList="projectField.canRefresh"
					:canUnlock="projectField.canUnlock"
					:custom-label="customProject"
					:selectedLabel="selectedText"
					:deselectLabel="deselectText"
					:selectLabel="selectText"
					@selected="assignedProject"
					@unselected="resetProject">
				</spot-select-custom>
				<spot-select-custom
					:ref="locationField.name"
					:label="locationField.text"
					:allignLabel="locationField.allignLabel"
					:required="locationField.required"
					:placeholder="getPlaceHolder(locationField)"
					:enabled="getEnabled(locationField, locationField.enabled)"
					:enabledExtraButtons="getEnabled(locationField, locationField.enabledExtraButtons)"
					:value="locationField.value"
					:options="tablesData.locations"
					:optionField="locationField.refField"
					:validationState="locationField.validationState"
					:canAddItem="locationField.canAddItem"
					:canRefreshList="locationField.canRefresh"
					:canUnlock="locationField.canUnlock"
					:custom-label="customLocation"
					:selectedLabel="selectedText"
					:deselectLabel="deselectText"
					:selectLabel="selectText"
					@selected="assignedLocation"
					@unselected="resetLocation"
					@addItem="addLocation">
				</spot-select-custom>
				<spot-button
					:ref="confirmButton.name"
					:text="confirmButton.text"
					:enabled="getEnabled(confirmButton, confirmButton.enabled)"
					:size="confirmButton.size"
					:fill="confirmButton.fill"
					:icon="confirmButton.icon"
					:expand="confirmButton.expand"
					:color="confirmButton.color"
					@clicked="manageInventory">
				</spot-button>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('1111111111111','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped>
</style>

<script>
	import { IonContent, IonPage, IonProgressBar } from '@ionic/vue';
	import { defineComponent } from 'vue';
	import SpotButton from '../components/SpotButton.vue';
	import SpotSelectCustom from '../components/SpotSelectCustom.vue';
	import SpotFooter from "../components/SpotFooter.vue";
	import SpotHeader from "../components/SpotHeader.vue";
	import WebSocketReader from '../components/WebSocketReader.vue';
	import { insertValue, settings, showMsgError, showMsgWarning, writeLog } from '../plugins/common.js';
	import { createLocation, getLocations, searchProjects, searchProjectTypology, tablesData } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "SelectInventory",
		props: {
			pageTitle: { type: String, default: 'Seleziona Inventario' },
			inventoryListTitle: { type: String, default: 'placeholder.inventory_with_master_data' },
			inventoryFreeTitle: { type: String, default: 'placeholder.inventory_without_master_data' },
			selectedText: { type: String, default: 'Selezionato' },
			deselectText: { type: String, default: 'annulla' },
			selectText: { type: String, default: 'seleziona' },
			unselected: { type: String, default: 'Nessuno' },
			newValueTitle: { type: String, default: "Inserimento Nuovo Valore" },
			messageSearchProjectTitle: { type: String, default: 'Ricerca Inventario' },
			messageSearchProjectNotFound: { type: String, default: 'Inventario non trovato!' },
			messageSearchLocationTitle: { type: String, default: 'Ricerca Ubicazione' },
			messageSearchLocationNotFound: { type: String, default: 'Ubicazione non trovata!' },
			messageTitleReader: { type: String, default: 'Lettura Barcode' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			SpotHeader,
			SpotFooter,
			SpotSelectCustom,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				project: 0,
				location: 1,
				confirm: 2,
			},
			fields: [
				{ id: 0, name: 'project', text: 'Inventario', enabled: null, enabledExtraButtons: null, allignLabel: true, placeholder: 'Seleziona Inventario', emptyValue: 'Nessuno', inputType: 'combo', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 1, name: 'location', text: 'Ubicazione', enabled: null, enabledExtraButtons: null, allignLabel: true, placeholder: 'Seleziona Ubicazione', emptyValue: 'Nessuno', inputType: 'combo', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: true, canRefresh: false, canUnlock: false },
				{ id: 2, name: 'confirm', text: 'Procedi', enabled: null, inputType: 'button', size: 'default', fill: 'solid', expand: 'block', icon: 'bagAdd', color: 'primary' },
			],
			lastBarcode: null,
			isFree: null,
			settings,
			showMsgError,
			showMsgWarning,
			writeLog,
			insertValue,
			searchProjectTypology,
			searchProjects,
			getLocations,
			createLocation,
			tablesData,
		}),
		computed: {
			projectField() { return this.fields[this.field.project] },
			locationField() { return this.fields[this.field.location] },
			confirmButton() { return this.fields[this.field.confirm] },
			isEmptyRequiredFields() {
				let ret = false;
				this.fields.forEach(element => {if (element.required && !element.value) ret=true});
				return ret;
			},
		},
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isFree = (/true/i).test(this.$route.query.free);
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.fields.forEach(element => (element.required == true? (element.value == null ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.lastBarcode = null;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			getEnabled(element, defaultState) {
				let ret = false;
				// if(this.isActive == true) {
				if (defaultState != null) ret = defaultState;
				else {
					switch (element.id) {
						case this.field.project:
							ret = !!tablesData.projects
							break;
						case this.field.location:
							ret = !!this.projectField.value
							break;
						case this.field.confirm:
							// ret = !!this.projectField.value && !! this.locationField.value
							ret = !this.isEmptyRequiredFields
							break;
					}
				}
				// }
				return ret
			},
			async getCustomLabel(element) {
				let ret = null;
				// if(this.isActive == true) {
					switch (element.id) {
						case this.field.project:
							ret = this.customProject
							break;
						case this.field.location:
							ret = this.customLocation
							break;
						// case this.field.confirm:
					}
				// }
				return ret
			},
			// Database
			async getAllTablesData() {
				this.tablesData.projects = [];
				this.tablesData.currentProject = null;
				this.tablesData.locations = [];
				this.tablesData.currentLocation = null;
				this.tablesData.currentProjectTypology = null;
				if (this.isFree == true && this.settings.inventoryAutoCreatePicks == false) await this.searchProjectTypology(this.inventoryFreeTitle);
				if (this.isFree == true && this.settings.inventoryAutoCreatePicks == true) await this.searchProjectTypology(this.inventoryListTitle);
				if (this.isFree == false) await this.searchProjectTypology(this.inventoryListTitle);
				await this.searchProjects(this.tablesData.currentProjectTypology.id);
			},
			/*
			customProject ({ name, customer }) {
				return name + " - " + (!customer ? "" : customer.name)
			},
			*/
			customProject ({ name }) {
				return name
			},
			/*
			customLocation ({ name, code }) {
				return name + " - " + code
			},
			*/
			customLocation ({ name }) {
				return name
			},
			async addLocation() {
				let enteredData = null;
				this.tablesData.currentLocation = null;
				await this.insertValue(this.newValueTitle, this.locationField.text)
				.then((data) => {
					if (data) { enteredData = data;	}
				})
				if (enteredData) {
					await this.createLocation(this.prepareNewLocation(enteredData));
					if (this.tablesData.currentLocation != null) {
						this.assignedLocation(this.tablesData.currentLocation);
						// this.fields[this.field.location].value = this.tablesData.currentLocation;
						// this.fields[this.field.location].validationState = true;
					}
				}
			},
			// Actions
			/*
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			*/
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				if (this.getEnabled(this.locationField, this.locationField.enabled) == true) await this.committedBarcode();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			async assignedProject(value) {
				this.tablesData.currentProject = value;
				await this.getLocations();
				this.projectField.value = value;
				this.projectField.validationState = true;
				this.resetLocation();
			},
			resetProject() {
				this.projectField.value = this.projectField.defaultValue;
				this.tablesData.currentProject = null;
				this.projectField.validationState = false;
				this.resetLocation();
			},
			async assignedLocation(value) {
				this.tablesData.currentLocation = value;
				this.locationField.value = value;
				this.locationField.validationState = true;
			},
			resetLocation() {
				this.locationField.value = this.locationField.defaultValue;
				this.tablesData.currentLocation = null;
				this.locationField.validationState = false;
			},
			prepareNewLocation(value) {
				let myRecord = {
					locations: {
						name: value,
						code: value,
					}
				};
				return myRecord;
			},
			async manageInventory() {
				if (this.isFree) {
					if (this.settings.inventoryAutoCreatePicks) {
						//TODO: Gestisci l'inserimento dati in picks con project bulk_upsert
						this.$router.push("/inventory/list_management/" + this.tablesData.currentProject.id +
							"?free="+this.isFree+
							"&location="+this.tablesData.currentLocation.id
						);
					}
					else {
						this.$router.push("/inventory/free_management/" + this.tablesData.currentProject.id +
							"?free="+this.isFree+
							"&location="+this.tablesData.currentLocation.id
						);
					}
				}
				else {
					this.$router.push("/inventory/list_management/" + this.tablesData.currentProject.id + 
						"?free="+this.isFree+
						"&location="+this.tablesData.currentLocation.id
					);
				}
			},
			/*
			async committedBarcode() {
				let foundElement = null;
				this.tablesData.projects.some(element => {
					if (element.name == this.lastBarcode) {
						foundElement = element;
						return true;
					}
				});
				if (!foundElement) this.showMsgWarning(this.messageSearchProjectTitle, this.messageSearchProjectNotFound);
				else await this.assignedProject(foundElement);
			},
			*/
			async committedBarcode() {
				let foundElement = null;
				this.tablesData.locations.some(element => {
					if (element.name == this.lastBarcode) {
						foundElement = element;
						return true;
					}
				});
				if (!foundElement) this.showMsgWarning(this.messageSearchLocationTitle, this.messageSearchLocationNotFound);
				else await this.assignedLocation(foundElement);
			},
		},
	});
</script>