<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="background-image">
				{{ version }}
			</div>
			<div class="login-form-container">
				<div id="section-logo">
					<div class="logo-container">
						<div class="logo text-center">
							<img :src="require(`@/assets/customer_logo.svg`)" />
						</div>
						<div class="welcome">{{ welcome }}</div>
					</div>
				</div>
				<div id="section-login">
					<ion-item class="input-form">
						<ion-icon :icon="mailOutline" slot="start"></ion-icon>
						<ion-input type="email" placeholder="Email" v-model="credentials.auth.email" required></ion-input>
					</ion-item>
					<ion-item class="input-form">
						<ion-icon :icon="lockClosedOutline" slot="start"></ion-icon>
						<ion-input type="password" placeholder="Password" v-model="credentials.auth.password" required></ion-input>
					</ion-item>
					<!--
					<div class="checkbox-remember">
						<ion-checkbox class="checkbox-square"></ion-checkbox>
						<ion-label class="checkbox-label">Ricordami</ion-label>
					</div>
					-->
					<ion-button shape="round" fill="solid" size="large" @click="executeLogin()">
						<ion-spinner v-if="loading"></ion-spinner>
						<span v-else>{{ signIn }}</span>
					</ion-button>
				</div>
			</div>
		</ion-content>
		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped src="../assets/css/login.css">
</style>

<script>
import { feName } from "@/plugins/variables.js";
import { IonButton, /*IonCheckbox, IonLabel*/ IonContent, IonIcon, IonInput, IonItem, IonPage, IonSpinner } from '@ionic/vue';
import { lockClosedOutline, mailOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import SpotFooter from "../components/SpotFooter.vue";
import { openToast } from "../plugins/common.js";
import { tablesData } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "LogIn",
		props: {
			signIn: { type: String, default: "Accedi"/*"Sign In"*/ },
			welcome: { type: String, default: "Accedi a " + feName, /*"Welcome back!"*/ },
			supplierText: { type: String, default: "powered by <strong>Bancolini Symbol</strong>" }, //"&nbsp&nbsp powered by <strong>Bancolini Symbol</strong>"
		},
		components: {
			IonPage,
			IonContent,
			IonItem,
			IonIcon,
			IonInput,
			// IonCheckbox,
			// IonLabel,
			IonButton,
			IonSpinner,
			SpotFooter,
		},
		data: () => ({
			version: null,
			loading: false,
			credentials: {
				auth: {
					email: null,
					password: null,
				},
			},
			mailOutline,
			lockClosedOutline,
			openToast,
			tablesData,
		}),
		watch: {
			loading() {
				if (this.loading === false) {
					this.clearAuthFields();
				} else {
					if (localStorage.getItem("user")) localStorage.removeItem("user");
				}
			}
		},
		ionViewWillEnter() {
			this.version = require('../../package.json').version;
		},
		methods: {
			clearAuthFields() {
				this.credentials.auth.email = null;
				this.credentials.auth.password = null;
			},
			executeLogin() {
				this.loading = true;
				this.tablesData.currentUser = null;
				this.$store.dispatch("tables/login", {
					model: "authenticate",
					data: this.credentials,
				}).then((data) => {
					if (data) {
						localStorage.setItem(
							"user",
							JSON.stringify(data)
						);
						this.tablesData.currentUser = data;
						this.$router.push("/home");
					}
					this.loading = false;
				}).catch((error) => {
					this.openToast(error, "warning");
					this.loading = false;
				});
			},
		},
	});
</script>
