<template>
	<ion-page>
		<spot-header :title="feName" :canGoHome="true" :canGoBack="false" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitOutgoing"></spot-header>
		<ion-header>
			<ion-toolbar>
				<ion-item lines="none">
					<spot-button :text="stringSave" :enabled="tagFounds.length>0 && projectField.value" size="small" fill="outline" @clicked="updateRows"></spot-button>
					<spot-button :text="stringDeleteList" :enabled="tagFounds.length>0" size="small" fill="outline" @clicked="clearList"></spot-button>
				</ion-item>
				<div class="report">
					<ion-label><strong>{{stringReads}}{{tagFounds.length}}</strong></ion-label>
				</div>
			</ion-toolbar>
		</ion-header>
		
		<ion-content> <!--v-if="isActive"--> <!-- :fullscreen="true" -->
			<ion-progress-bar type="indeterminate" v-if="!isActive || isManagingTags == true"></ion-progress-bar>
			<div id="actionArea" v-if="isActive">
				<spot-select
					:label="projectField.text"
					:allignLabel="projectField.allignLabel"
					:required="projectField.required"
					:placeholder="getPlaceHolder(projectField)"
					:enabled="!isManagingTags"
					:value="projectField.value"
					:options="tablesData.projects"
					:optionField="projectField.refField"
					:validationState="projectField.validationState"
					:canAddItem="projectField.canAddItem"
					:canRefreshList="projectField.canRefresh"
					:canUnlock="projectField.canUnlock"
					selectedLabel="Selezionato"
					deselectLabel="rimuovi"
					selectLabel="seleziona"
					@selected="selectedProject"
					@unselected="projectField.value = projectField.defaultValue"
					@addItem="createProject"
					@refresh="customSearchProjects">
				</spot-select>
				<ion-loading
						:is-open="isSaving"
						:message="infoSaving"
						color="primary"
						spinner="bubbles"
				></ion-loading>
				<web-socket-reader crReplacedChar="|" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<ion-card>
					<div v-for="item in itemsList" :key="item.description">
						<ion-item>
							<ion-label>{{item.quantity}}</ion-label>
							<ion-input v-model="item.description" :disabled="true"></ion-input>
						</ion-item>
					</div>
				</ion-card>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>{{stringTestArea}}</div>
				<spot-button :text="stringSimulateReads" size="small" fill="outline" expand=undefined @clicked="simulateTagReads"></spot-button>
				<div>{{ tagReads.length }}{{ tagReads }}</div>
				<div>{{ tagFounds.length }}{{ tagFounds }}</div>
				<div>{{ itemsList.length }}{{ itemsList }}</div>
			</div>
		</ion-content>

		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped>
	ion-content {
		display: flex;
		flex-direction: column;
	}
	/*
	ion-content *:deep(ion-label) {
		min-width: 25vw !important;
		max-width: 25vw !important;
	}
	*/
	ion-item {
		--padding-bottom:	0.5vh;
		--padding-top:	0.5vh;
		--min-height: 0px;
	}
	ion-item :deep(*) {
    margin-bottom: 0px;
    margin-top: 0px;
    --padding-bottom: 0px;
    --padding-top: 0px;
	}
	.report {
		display:block;
		text-align: center;
		font-size: min(5vw,5vh);
	}					
</style>

<script>
import { feName } from '@/plugins/variables.js';
import { IonCard, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonProgressBar, IonToolbar } from '@ionic/vue';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotFooter from '../components/SpotFooter.vue';
import SpotHeader from '../components/SpotHeader.vue';
import SpotSelect from '../components/SpotSelect.vue';
import WebSocketReader from '../components/WebSocketReader.vue';
import { checkUserSession, insertValue, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';

export default defineComponent({
		name: 'OutgoingItems',
		props: {
			// pageTitle: { type: String, default: 'Uscita Merce' },
			stringSave: { type: String, default: 'Salva' },
			stringDeleteList: { type: String, default: 'Cancella lista' },
			stringReads: { type: String, default: 'Totale letture: ' },
			stringTestArea: { type: String, default: 'Test Area' },
			stringSimulateReads: { type: String, default: 'Simula lettura' },
			typologyTitle: { type: String, default: 'Uscita Merce Senza Lista' },
			newValueTitle: { type: String, default: 'Inserimento Nuovo Valore' },
			messageTitleSave: { type: String, default: 'Salvataggio Dati' },
			messageTitleSearch: { type: String, default: 'Ricerca Elemento' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			successSaveMessage: { type: String, default: 'Movimentazione salvata correttamente!' },
			errorSaveProject: { type: String, default: 'Errore durante salvataggio Testata Movimento' },
			errorSaveProjectRow: { type: String, default: 'Errore durante il salvataggio Dettaglio Movimento' },
			errorSearchProjectRow: { type: String, default: 'Errore nella ricerca del TAG' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
			infoSaving: { type: String, default: 'Salvataggio in corso...' },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			SpotHeader,
			SpotSelect,
			SpotButton,
			SpotFooter,
			WebSocketReader,
			IonItem,
			IonLabel,
			IonInput,
			IonHeader,
			IonToolbar,
			IonCard,
			IonLoading,
		},
		data: () => ({
			isActive: null,
			isSaving: false,
			isManagingTags: false,
			isOpenModal: false,
			fields: [
				{ id: 0, name: 'project', text: 'Cliente', allignLabel: true, placeholder: 'Seleziona cliente', emptyValue: 'Nessuno', inputType: 'combo', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: true, canRefresh: true, canUnlock: false },
			],
			tablesData: {
				project_typology: null,
				projects: [],
			},
			tagReads: [],
			tagFounds: [],
			itemsList: [],
			feName,
			checkUserSession,
			settings,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			insertValue,
			writeLog,
			simulateMultipleReads: null, // debug
			currTest: 0, // debug
		}),
		watch: {
			tagReads: {
				deep: true,
				handler() {
					if (this.tagReads.length > 0 && !this.isManagingTags) this.manageAllTagReads();
				}
			},
			tagFounds: {
				deep: true,
				handler() {
					this.updateItemList()
				}
			},
  },
		computed: {
			projectField() { return this.fields[0] },
		},
		async ionViewWillEnter() {
			this.setDefault();
			await this.getAllTablesData();
			this.isActive=true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			// Settings & StartUp
			setDefault() {
				this.initAll();
			},
			getPlaceHolder(inputElement) {
				return ((this.settings.showFieldsPlaceholder) ? (inputElement.required ? inputElement.placeholder : inputElement.emptyValue ) : '')
			},
			async getAllTablesData() {
				await this.searchAndGetProjectTypology();
				await this.customSearchProjects();
			},
			// Database
			async writeData(tableName, record) {
				let data = await this.$store.dispatch('tables/create', { model: tableName, data: record })
				return data;
			},
			async updateData(tableName, recordId, record) {
				let data = await this.$store.dispatch('tables/update', { model: tableName, id: recordId, data: record })
				return data;
			},
			async searchData(tableName, filters) {
				let data = await this.$store.dispatch('tables/querySearch', { model: tableName, data: filters })
				return data;
			},
			async searchAndGetProjectTypology() {
				let mySearch = {
					params: {
						'q[title_eq]': this.typologyTitle,
					}
				};
				await this.searchData('project_typologies', mySearch)
				.then(data => { if (data.length>0) { this.tablesData.project_typology = data[0]; } })
				.catch(error => { this.checkUserSession(error, 'warning'); })
				if (this.tablesData.project_typology == null) {
					await this.writeData('project_typologies', this.prepareProjectTypology())
					.then(data => { this.tablesData.project_typology = data[0]; })
					.catch(error => { this.checkUserSession(error, 'warning'); })
				}
			},
			async customSearchProjects() {
				let mySearch = {
					params: {
						'q[project_typology_id_eq]': this.tablesData.project_typology.id,
					}
				};
				await this.searchData('projects', mySearch)
				.then(data => { this.tablesData.projects = data; })
				.catch(error => { this.tablesData.projects = []; this.checkUserSession(error, 'warning'); });
			},
			async createProject() {
				this.insertValue(this.newValueTitle, this.projectField.text)
					.then(async (data) => {
						if (data != null) {
							await this.writeData('project', this.prepareProject(data))
							.then((data) => { this.customSearchProjects(); this.projectField.value = data; })
							.catch(error => {
								this.checkUserSession(error, 'warning');
								this.showMsgError(this.messageTitleSave, this.errorSaveProject);
							})
						}
					})
			},
			async updateRows() {
				var state = true;

				this.isSaving = true;
				for (let i = 0; i < this.tagFounds.length; i++) {
					await this.updateData('project_rows', this.tagFounds[i].id, this.prepareProjectRow(i))
					.then(() => {})
					.catch(error => {
						state = false;
						this.checkUserSession(error, 'warning');
						this.showMsgError(this.messageTitleSave, this.errorSaveProjectRow);
					})
				}
				this.isSaving = false;

				if (state) {
					this.showMsgInfo(this.messageTitleSave, this.successSaveMessage);
					this.setDefault();
				}
			},
			// Events
			async onReadedTag(tag,type) {
				this.writeLog(tag, type);
				await this.committedTags(tag);
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			selectedProject(value) {
				this.projectField.value = value;
			},
			clearList() {
				this.initAll(true);
			},
			async committedTags(tags) {
				let tagElements = tags.split('|');
				await tagElements.forEach(element => {
					if (!this.tagReads.includes(element)) {
						this.tagReads.push(element);
					}
				});
			},
			async manageAllTagReads() {
				this.isManagingTags = true;
				while (this.tagReads.length > 0) {
					await this.manageSingleTagRead();
				}
				this.isManagingTags = false;
			},
			async manageSingleTagRead() {
				var foundTag = this.tagFounds.findIndex(row => {
					return row.code == this.tagReads[0];
				});
				if (foundTag <0) {
					let mySearch = {
						params: {
							'q[code_eq]': this.tagReads[0],
						}
					};
					await this.searchData('project_rows', mySearch)
					.then(data => {
						if (data.length>0) {
							this.tagFounds.push(data[0]);
						}
						this.tagReads.shift();
					})
					.catch(error => {
						this.checkUserSession(error, 'warning');
						this.showMsgError(this.messageTitleSearch, this.errorSearchProjectRow);
					})
				}
				else {
					this.tagReads.shift();
				}
			},
			updateItemList() {
				if (this.tagFounds.length > 0) {
					var itemsGroup = this.tagFounds.reduce((myResult, row) => {
						var name = row.item.description;
						myResult[name] = myResult[name] || 0;
						myResult[name]++;
						return myResult;
					}, {});

					this.itemsList = Object.keys(itemsGroup).map(key => {
						return {description: key, quantity: itemsGroup[key]};
					});
				}
				else {
					this.itemsList = [];
				}
			},
			// Actions
			async initAll(exceptProject = false) {
				if (!exceptProject) this.projectField.value = this.projectField.defaultValue;
				this.initReads();
			},
			initReads() {
				this.tagReads = [];
				this.tagFounds = [];
				// this.itemsList = []; automatic reset on this.tagFounds changes
			},
			prepareProjectTypology() {
				let myRecord = {
					project_typology: {
						title: this.typologyTitle,
						inventory: false,
						picking: true,
						free: true,
					}
				};
				return myRecord;
			},
			prepareProject(projectName) {
				let myRecord = {
					project: {
						name: projectName,
						project_typology_id: this.tablesData.project_typology.id,
					}
				};
				return myRecord;
			},
			prepareProjectRow(index) {
				let myRecord = {
					project_row: {
						project_id: this.projectField.value.id,
						description: this.tagFounds[index].item.description,
					}
				};
				return myRecord;
			},
			async exitOutgoing() {
				let canExit = !this.isOpenModal;
				
				if (canExit == true) {
					this.$router.push("/home");
				}
			},
			// Debug
			simulateTagReads() {
				this.StartTestMultipleRead();
			},
			StartTestMultipleRead() {
				this.writeLog('1. Starting MultipleReads');
				this.simulateMultipleReads = setInterval(this.SendData, 100);
			},
			StopTestMultipleReads() {
				this.writeLog('7. Stopping MultipleReads');
				clearInterval(this.simulateMultipleReads);
			},
			SendData() {
				switch(this.currTest) {
					case 0:
						this.writeLog('2. Sending Tag');
						this.onReadedTag('AA0A67170000000000804617|AA0A67170000000000804618|AA0A67170000000000804608|AA0A67170000000000804544|AA0A67170000000000804579|AA0A67170000000000804546|AA0A67170000000000804547|AA0A67170000000000804548|AA0A67170000000000804550|AA0A67170000000000804556|AA0A67170000000000804557|AA0A67170000000000804561|AA0A67170000000000804561|AA0A67170000000000804562|AA0A67170000000000804590|AA0A67170000000000804563|AA0A67170000000000804564|AA0A67170000000000804597|AA0A67170000000000804565|AA0A67170000000000804566|AA0A67170000000000804567|AA0A67170000000000804568|AA0A67170000000000804570|AA0A67170000000000804570|AA0A67170000000000804549|AA0A67170000000000804617|AA0A67170000000000804570|AA0A67170000000000804572|AA0A67170000000000804579|AA0A67170000000000804586','TAG');
						this.currTest += 1;
						break;
					case 1:
						this.writeLog('3. Sending Tag');
						this.onReadedTag('AA0A67170000000000804540|AA0A67170000000000804574|AA0A67170000000000804589|AA0A67170000000000804545|AA0A67170000000000804591|AA0A67170000000000804565|AA0A67170000000000804598|AA0A67170000000000804567|AA0A67170000000000804570|AA0A67170000000000804540|AA0A67170000000000804574|AA0A67170000000000804547|AA0A67170000000000804557|AA0A67170000000000804562|AA0A67170000000000804590','TAG');
						this.currTest += 1;
						break;
					case 2:
						this.writeLog('4. Sending Tag');
						this.onReadedTag('AA0A67170000000000804617|AA0A67170000000000804618|AA0A67170000000000804571|AA0A67170000000000804572|AA0A67170000000000804573|AA0A67170000000000804433|AA0A67170000000000804599|AA0A67170000000000804608|AA0A67170000000000804540|AA0A67170000000000804574|AA0A67170000000000804541|AA0A67170000000000804575|AA0A67170000000000804542|AA0A67170000000000804544|AA0A67170000000000804579|AA0A67170000000000804586|AA0A67170000000000804589|AA0A67170000000000804545|AA0A67170000000000804546|AA0A67170000000000804547|AA0A67170000000000804548|AA0A67170000000000804549|AA0A67170000000000804550|AA0A67170000000000804553|AA0A67170000000000804554','TAG');
						this.currTest += 1;
						break;
					case 3:
						this.writeLog('5. Sending Tag');
						this.onReadedTag('AA0A67170000000000804555|AA0A67170000000000804556|AA0A67170000000000804557|AA0A67170000000000804558|AA0A67170000000000804560|AA0A67170000000000804561|AA0A67170000000000804562|AA0A67170000000000804590|AA0A67170000000000804591|AA0A67170000000000804563|AA0A67170000000000804564|AA0A67170000000000804597|AA0A67170000000000804565|AA0A67170000000000804598|AA0A67170000000000804566|AA0A67170000000000804567|AA0A67170000000000804568|AA0A67170000000000804569|AA0A67170000000000804570|AA0A67170000000000804617','TAG');
						this.currTest += 1;
						break;
					default:
						this.writeLog('6. Ending Sending Tag');
						this.StopTestMultipleReads();
						this.currTest = 0;
				}
			}
		}
	});
</script>