<template>
	<ion-button
		ref="button"
		:disabled="!enabled"
		:size="size"
		:fill="fill"
		:expand="expand"
		:color="color"
		@click="$emit('clicked')">
		<ion-icon v-if="icon" :src="getIcon(icon)" :size="size"></ion-icon>
		{{ text }}
	</ion-button>
</template>

<style scoped>
	ion-button {
		margin-top: .5em;
		margin-left: .25em;
		margin-right: .25em;
		font-size: 20px;
		--padding-start: 2vw;
		--padding-end: 2vw;
	}
	ion-button ion-icon {
		font-size: 20px;
		--padding-start: 2vw;
		--padding-end: 2vw;
	}
</style>

<script>
import { IonButton, IonIcon } from '@ionic/vue';
import * as allIcons from "ionicons/icons";
import { defineComponent } from 'vue';

	export default defineComponent({
		props: {
			text: { type: String, default: "Button" },
			enabled: { type: Boolean, default: true },
			size: { type: String, default: "large" },
			fill: { type: String, default: "solid" },
			expand: { type: String, default: "block" },
			color: { type: String, default: "primary" },
			icon: { type: String, default: null },
			//TODO: add (fontSize)
		},
		components: {
			IonButton,
			IonIcon,
		},
		emits: [ "clicked" ],
		data: () => ({
			icons: allIcons,
		}),
		methods: {
			getIcon(name) {
				return this.icons[name];
			},
		},
	});
</script>
