<template>
	<div class="label" v-if="!allignLabel && field.label">
		<ion-label>{{ field.label }}</ion-label>
	</div>
	<div :class="{input: !allignLabel, inputLabel: allignLabel}">
		<ion-label class="ion-align-self-center" v-if="allignLabel && field.label">{{ field.label }}</ion-label>
		<ion-datetime-button
			:class="{defaultBorder: validationState == null || !enabled, invalidBorder: enabled && validationState == false, validBorder: enabled && validationState == true}"
			datetime="dateTimeInput"
			:color="color"
			:disabled="!field.enabled"
			slots="date-target">
		</ion-datetime-button>
		<ion-modal :keep-contents-mounted="true">
			<ion-datetime
				id="dateTimeInput"
				:presentation="type"
				v-model="field.value"
				ref="input"
				:disabled="!field.enabled"
				:show-default-buttons="true"
				:done-text="doneText"
				:cancel-text="cancelText"
				:value="new Date().toISOString()"
				@ionChange="detectDateChange"
				@ionCancel="detectCancelButton">
			</ion-datetime>
		</ion-modal>
	</div>
</template>

<style scoped>
	* {
		--padding-bottom:	0px;
		--padding-top:	0px;
		/*margin-right: .5em;*/
	}
	ion-label {
		font-weight: bold;
		/*margin-top: .7em;*/
	}
	.defaultBorder {
		border: 1px solid var(--ion-color-light-shade) !important;
	}
	.invalidBorder {
		border: 1px solid red !important;
	}
	.validBorder {
		border: 1px solid green !important;
	}
	.label {
		margin-top: .5em;
		margin-left: .5em;
	}
	.input {
		display: flex; /*inline-block;*/
		flex-direction: row;
		vertical-align: baseline;
		text-align: left;
		margin-left: .5em;
		margin-right: .5em;
	}
	.inputLabel {
		display: flex; /*inline-block;*/
		flex-direction: row;
		vertical-align: baseline;
		text-align: left;
		margin-top: .5em;
		margin-left: .5em;
		margin-right: .5em;
	}
	ion-datetime-button::part(native) {
		background: #FFF;
	}
</style>

<script>
import { IonLabel, IonDatetimeButton, IonModal, IonDatetime } from '@ionic/vue';
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		label: { type: String, default: "Label" },
		allignLabel: { type: Boolean, default: false },
		required: { type: Boolean, default: true },
		value: { type: String, default: null },
		enabled: { type: Boolean, default: true },
		type: { type: String, default: "date" },
		validationState: { type: Boolean, default: null },
		color: { type: String, default: "primary" },
		doneText: { type: String, default: "done" },
		cancelText: { type: String, default: "cancel" },
	},
	components: {
		IonLabel,
		IonDatetimeButton,
		IonModal,
		IonDatetime,
	},
	emits: [ "dateChanged", "cancel" ],
	data: () => ({
		field: {
			label: null,
			value: null,
			enabled: true,
		},
	}),
	mounted () {
		this.field.label = (!this.label) ? this.label : ((!this.required) ? this.label : this.label + "*");
		this.field.value = this.value;
		this.field.enabled = this.enabled;
	},
	watch: {
		label () {
			this.field.label = (!this.label) ? this.label : ((!this.required) ? this.label : this.label + "*");
		},
		value () {
			this.field.value = this.value;
		},
		enabled () {
			this.field.enabled = this.enabled;
		},
	},
	methods: {
		detectDateChange() {
			setTimeout(() => this.$emit('dateChanged', this.field.value), 0);
		},
		detectCancelButton() {
			setTimeout(() => this.$emit('cancel'), 0);
		},
	}
});
</script>
