<script>
	import { defineComponent } from "vue";
	
	var barcodeScanRegExp = /Barcode \((.*)\) \[(.*)\]/

	export default defineComponent( {
		name: "WebSocketReader",
		props: {
			crReplacedChar: { type: String, default: "|" },
		},
		emits: [ "readedBarcode", "readedTag", "readerError" ],
		data () {
			return {
				data: '',
				code: '',
				type: '',
			}
		},
		mounted () {
			this.$options.sockets.onmessage = event => {
				try {
					// console.log(event.data)
					this.data = event.data.replaceAll("\n",this.crReplacedChar)					
					// console.log(this.data)
					this.code = barcodeScanRegExp.exec(this.data)[1]
					this.type = barcodeScanRegExp.exec(this.data)[2]
					if (this.type == this.code) {
						this.$emit('readedTag', this.code, "TAG")
					}
					else {
						this.$emit('readedBarcode', this.code, this.type)
					}
					// console.log(barcode)
				} catch (error) {
					this.$emit('readerError', this.data, error)
				}
			}
		},
		beforeUnmount () {
			delete this.$options.sockets.onmessage
			// console.log("web socket on message destroyed")
		},
	})
</script>
