export const backendUrl="https://mytrack3dev-admin.labs.bancolini.com"
export const backendRelativePath=""
export let backendURLWithRelativePath=`${backendUrl}${backendRelativePath}`
export let apiURL=`${backendURLWithRelativePath}/api/v2`
// These consts, if available, do manage public sites
// They will access with a token
// and have all the same user (the one, on the backend, which is associated to this token)
// If publicAccess is true, then the other constants become relevant, otherwise, not
export const publicAccess = false
export const accessToken = ""

export const feName = "MY WAREHOUSE"
